export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: Date; output: Date; }
  Email: { input: string; output: string; }
  JSON: { input: { [key: string]: any }; output: { [key: string]: any }; }
  UUID: { input: string; output: string; }
  Upload: { input: Promise<{createReadStream:() => ReadableStream, filename: string, mimetype: string, encoding: string}>; output: Promise<{createReadStream:() => ReadableStream, filename: string, mimetype: string, encoding: string}>; }
};

export type Ad = {
  __typename: 'Ad';
  attractiveness?: Maybe<Scalars['Int']['output']>;
  badge?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  interest?: Maybe<Scalars['String']['output']>;
  like?: Maybe<Scalars['Int']['output']>;
  link: Scalars['String']['output'];
  localPosition?: Maybe<Scalars['String']['output']>;
  priceAboveAverage?: Maybe<Scalars['Boolean']['output']>;
  priceEstimate?: Maybe<Scalars['Int']['output']>;
  source: AdSource;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  view: Scalars['Int']['output'];
};

export enum AdSource {
  AUTOSCOUT24 = 'AUTOSCOUT24',
  CAPCAR = 'CAPCAR',
  LA_CENTRALE = 'LA_CENTRALE',
  LEBONCOIN = 'LEBONCOIN',
  PARUVENDU = 'PARUVENDU',
  ZOOMCAR = 'ZOOMCAR'
}

export type Address = {
  __typename: 'Address';
  city: Scalars['String']['output'];
  country: CountryIsoAlpha2;
  locationId?: Maybe<Scalars['String']['output']>;
  street: Scalars['String']['output'];
  zipCode: Scalars['String']['output'];
};

export type AddressInput = {
  city: Scalars['String']['input'];
  country: CountryIsoAlpha2;
  locationId?: InputMaybe<Scalars['String']['input']>;
  street: Scalars['String']['input'];
  zipCode: Scalars['String']['input'];
};

export type Advisor = {
  __typename: 'Advisor';
  email: Scalars['Email']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
};

export type Agent = {
  __typename: 'Agent';
  description?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isTrainer?: Maybe<Scalars['Boolean']['output']>;
  lastName: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  pic?: Maybe<Scalars['String']['output']>;
};

export type AgentApplicant = {
  __typename: 'AgentApplicant';
  enrollmentInfoValidatedAt?: Maybe<Scalars['Date']['output']>;
  firstName: Scalars['String']['output'];
  gender?: Maybe<UserGender>;
  id: Scalars['Int']['output'];
  initialPaymentReceivedAt?: Maybe<Scalars['Date']['output']>;
  lastName: Scalars['String']['output'];
  leadPhase?: Maybe<AgentApplicantLeadPhase>;
  sponsor?: Maybe<Agent>;
  startingDate?: Maybe<Scalars['Date']['output']>;
};

export type AgentApplicantAddressInput = {
  addressCity: Scalars['String']['input'];
  addressStreet: Scalars['String']['input'];
  addressZipCode: Scalars['String']['input'];
  countryOfResidence: CountryIsoAlpha2;
  locationId?: InputMaybe<Scalars['String']['input']>;
};

export type AgentApplicantCreationResult = {
  __typename: 'AgentApplicantCreationResult';
  meta: Scalars['JSON']['output'];
  status: AgentApplicantCreationStatus;
};

export enum AgentApplicantCreationStatus {
  ALREADY_EXISTS = 'ALREADY_EXISTS',
  CREATED = 'CREATED'
}

export type AgentApplicantEnrollmentInput = {
  bankDetailsFile: Scalars['Upload']['input'];
  bic: Scalars['String']['input'];
  birthday: Scalars['Date']['input'];
  gender: UserGender;
  iban: Scalars['String']['input'];
  placeOfBirth: Scalars['String']['input'];
  startingDate: Scalars['Date']['input'];
};

export enum AgentApplicantLeadPhase {
  NEW = 'NEW',
  NOT_INTERESTED = 'NOT_INTERESTED',
  PROPOSAL_SENT = 'PROPOSAL_SENT',
  SIGNED = 'SIGNED',
  TO_FOLLOW_UP = 'TO_FOLLOW_UP'
}

export type AgentPaymentAttempt = {
  __typename: 'AgentPaymentAttempt';
  id: Scalars['Int']['output'];
};

export enum AgentPaymentAttemptType {
  ENROLLMENT = 'ENROLLMENT'
}

export type Alert = {
  __typename: 'Alert';
  brands: Array<Scalars['String']['output']>;
  categories: Array<Scalars['String']['output']>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  email: Scalars['Email']['output'];
  fuels: Array<Scalars['String']['output']>;
  gearboxes: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  maxMileage?: Maybe<Scalars['Int']['output']>;
  maxPrice?: Maybe<Scalars['Int']['output']>;
  maxYear?: Maybe<Scalars['Int']['output']>;
  minMileage?: Maybe<Scalars['Int']['output']>;
  minPrice?: Maybe<Scalars['Int']['output']>;
  minYear?: Maybe<Scalars['Int']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  versions: Array<Scalars['String']['output']>;
};

export type AlertInput = {
  brands?: InputMaybe<Array<Scalars['String']['input']>>;
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  email: Scalars['Email']['input'];
  fuels?: InputMaybe<Array<Scalars['String']['input']>>;
  gearboxes?: InputMaybe<Array<Gearbox>>;
  maxMileage?: InputMaybe<Scalars['Int']['input']>;
  maxPrice?: InputMaybe<Scalars['Int']['input']>;
  maxYear?: InputMaybe<Scalars['Int']['input']>;
  minMileage?: InputMaybe<Scalars['Int']['input']>;
  minPrice?: InputMaybe<Scalars['Int']['input']>;
  minYear?: InputMaybe<Scalars['Int']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  versions?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type Appointment = {
  __typename: 'Appointment';
  address: Scalars['String']['output'];
  endDate: Scalars['Date']['output'];
  gmapUrl: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  startDate: Scalars['Date']['output'];
  status: AppointmentStatus;
};

export type AppointmentAvailabilities = {
  __typename: 'AppointmentAvailabilities';
  address: Address;
  id: Scalars['ID']['output'];
  slots: Array<AvailabilitySlot>;
};

export enum AppointmentStatus {
  CANCELED = 'CANCELED',
  DONE = 'DONE',
  PENDING = 'PENDING'
}

export type AutomaticPricing = {
  __typename: 'AutomaticPricing';
  averageTimeToSale: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  marketPrice: Scalars['JSON']['output'];
  negociation: Scalars['Int']['output'];
  price: Scalars['Int']['output'];
  scoreRecognition: Scalars['Int']['output'];
  scoreValuation: Scalars['Int']['output'];
};

export enum AvailabilityRank {
  Slot1 = 'Slot1',
  Slot2 = 'Slot2',
  Slot3 = 'Slot3'
}

export type AvailabilitySlot = {
  __typename: 'AvailabilitySlot';
  endDate: Scalars['Date']['output'];
  rank: AvailabilityRank;
  startDate: Scalars['Date']['output'];
};

export type AvailabilitySlotInput = {
  endDate: Scalars['Date']['input'];
  startDate: Scalars['Date']['input'];
};

export type BankDetails = {
  __typename: 'BankDetails';
  bic: Scalars['String']['output'];
  country: Scalars['String']['output'];
  customerId: Scalars['Int']['output'];
  date: Scalars['Date']['output'];
  iban: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  owner: Scalars['String']['output'];
  productId: Scalars['ID']['output'];
  type: BankDetailsType;
  walletId: Scalars['Int']['output'];
};

export enum BankDetailsType {
  BUYER = 'BUYER',
  SELLER = 'SELLER'
}

export type Bargaining = {
  __typename: 'Bargaining';
  acceptPriceDrop: Scalars['Boolean']['output'];
  amount: Scalars['Int']['output'];
  answeredAt?: Maybe<Scalars['Date']['output']>;
  commission: Scalars['Int']['output'];
  counterBargaining?: Maybe<CounterBargaining>;
  createdAt: Scalars['Date']['output'];
  disabled: Scalars['Boolean']['output'];
  expireAt: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  isPendingPriceDrop?: Maybe<Scalars['Boolean']['output']>;
  offerId: Scalars['ID']['output'];
  product: Product;
  productId: Scalars['ID']['output'];
  status: BargainingStatus;
  updatedAt: Scalars['Date']['output'];
};

export type BargainingInput = {
  amount: Scalars['Int']['input'];
  productId: Scalars['String']['input'];
};

export enum BargainingStatus {
  ACCEPTED = 'ACCEPTED',
  ACCEPTED_EXPIRED = 'ACCEPTED_EXPIRED',
  AUTO_REFUSED = 'AUTO_REFUSED',
  EXPIRED = 'EXPIRED',
  PENDING = 'PENDING',
  REFUSED = 'REFUSED'
}

export type BookingInfos = {
  __typename: 'BookingInfos';
  appointment?: Maybe<BookingInfosAppointment>;
  booking?: Maybe<BookingInfosBooking>;
  oldPaymentInfos?: Maybe<OldBookingInfosPayment>;
  paymentInfos?: Maybe<BookingInfosPayment>;
  product?: Maybe<Product>;
  productId?: Maybe<Scalars['String']['output']>;
};

export type BookingInfosAppointment = {
  __typename: 'BookingInfosAppointment';
  address?: Maybe<BookingInfosAppointmentAddress>;
  date?: Maybe<Scalars['Date']['output']>;
  delivery: Scalars['Boolean']['output'];
  scheduled?: Maybe<Scalars['Boolean']['output']>;
};

export type BookingInfosAppointmentAddress = {
  __typename: 'BookingInfosAppointmentAddress';
  city: Scalars['String']['output'];
  streetName: Scalars['String']['output'];
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type BookingInfosBooking = {
  __typename: 'BookingInfosBooking';
  booked: Scalars['Boolean']['output'];
  date: Scalars['Date']['output'];
  status: Scalars['String']['output'];
};

export type BookingInfosPayment = {
  __typename: 'BookingInfosPayment';
  check?: Maybe<BookingInfosPaymentCheck>;
  creditCard: BookingInfosPaymentCreditCard;
  wallet: BookingInfosPaymentWallet;
};

export type BookingInfosPaymentCheck = {
  __typename: 'BookingInfosPaymentCheck';
  amount: Scalars['Int']['output'];
  fees: Scalars['Int']['output'];
  number: Scalars['String']['output'];
  order: Scalars['String']['output'];
  pic: Scalars['String']['output'];
};

export type BookingInfosPaymentCreditCard = {
  __typename: 'BookingInfosPaymentCreditCard';
  amount: Scalars['Int']['output'];
};

export type BookingInfosPaymentWallet = {
  __typename: 'BookingInfosPaymentWallet';
  amount: Scalars['Int']['output'];
  id: Scalars['String']['output'];
};

export type Brake = {
  __typename: 'Brake';
  brakePadWear?: Maybe<Scalars['Int']['output']>;
  brakePadWearCustom?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  disks?: Maybe<Rating>;
  id: Scalars['ID']['output'];
  isDrum: Scalars['Boolean']['output'];
  position: PositionBrake;
  productId: Scalars['String']['output'];
};

export type Brand = {
  __typename: 'Brand';
  id: Scalars['Int']['output'];
  mostSearched: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type Breaking = Transaction & {
  __typename: 'Breaking';
  amount: Scalars['Int']['output'];
  createdAt: Scalars['Date']['output'];
  error?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  source?: Maybe<TransactionSource>;
  status: Status;
  type: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
};

export type BreakingPayment = {
  __typename: 'BreakingPayment';
  breaking?: Maybe<Breaking>;
  signature?: Maybe<Scalars['String']['output']>;
  vads?: Maybe<Scalars['JSON']['output']>;
};

export enum BuyFrom {
  CAR_AGENT = 'CAR_AGENT',
  INDIVIDUAL = 'INDIVIDUAL',
  INDIVIDUAL_ABROAD = 'INDIVIDUAL_ABROAD',
  INDIVIDUAL_IN_FRANCE = 'INDIVIDUAL_IN_FRANCE',
  NEW = 'NEW',
  PROFESSIONAL = 'PROFESSIONAL',
  PROFESSIONAL_ABROAD = 'PROFESSIONAL_ABROAD',
  PROFESSIONAL_IN_FRANCE = 'PROFESSIONAL_IN_FRANCE',
  UNKNOWN = 'UNKNOWN'
}

export type CarInfos = {
  __typename: 'CarInfos';
  brand: Scalars['String']['output'];
  carPackage: Scalars['String']['output'];
  dinpower?: Maybe<Scalars['Int']['output']>;
  energy: Energy;
  gearbox: Gearbox;
  generation?: Maybe<Scalars['String']['output']>;
  motorization: Scalars['String']['output'];
  power: Scalars['Int']['output'];
  version: Scalars['String']['output'];
};

export type CarOption = {
  __typename: 'CarOption';
  category: CarOptionCategory;
  id: Scalars['Int']['output'];
  label: Scalars['String']['output'];
  ubiflowTag: Scalars['String']['output'];
};

export enum CarOptionCategory {
  COMFORT = 'COMFORT',
  MISCELLANEOUS = 'MISCELLANEOUS',
  SECURITY = 'SECURITY'
}

export enum CardanInGoodCondition {
  NO = 'NO',
  NOT_ACCURATE = 'NOT_ACCURATE',
  YES = 'YES'
}

export type Cart = {
  __typename: 'Cart';
  commissionPrice: Scalars['Int']['output'];
  formalityPrice: Scalars['Int']['output'];
  graycardPrice?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  productId: Scalars['String']['output'];
  productPrice: Scalars['Int']['output'];
  totalPrice: Scalars['Int']['output'];
  warrantyDiscount?: Maybe<Scalars['Int']['output']>;
  warrantyDuration?: Maybe<Scalars['Int']['output']>;
  warrantyName?: Maybe<Scalars['String']['output']>;
  warrantyPrice?: Maybe<Scalars['Int']['output']>;
};

export type CartCreationInput = {
  productId: Scalars['String']['input'];
};

export type CartInput = {
  graycard?: InputMaybe<Scalars['Boolean']['input']>;
  warrantyDuration?: InputMaybe<Scalars['Int']['input']>;
  warrantyName?: InputMaybe<Scalars['String']['input']>;
};

export type Confirmation = {
  __typename: 'Confirmation';
  confirmed?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  productId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<ConfirmationType>;
};

export enum ConfirmationType {
  CAR_TO_SELL = 'CAR_TO_SELL',
  FINNANCING = 'FINNANCING'
}

export type ContactMessageInput = {
  email: Scalars['Email']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  message: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  subject: Scalars['String']['input'];
};

export type CounterBargaining = {
  __typename: 'CounterBargaining';
  acceptPriceDrop: Scalars['Boolean']['output'];
  amount: Scalars['Int']['output'];
  commission: Scalars['Int']['output'];
  createdAt: Scalars['Date']['output'];
  expireAt: Scalars['Date']['output'];
  offerId: Scalars['ID']['output'];
  status: BargainingStatus;
  updatedAt: Scalars['Date']['output'];
};

export type CounterBargainingInput = {
  amount: Scalars['Int']['input'];
  bargainingId: Scalars['ID']['input'];
  productId: Scalars['String']['input'];
};

export enum CountryIsoAlpha2 {
  AD = 'AD',
  AE = 'AE',
  AF = 'AF',
  AG = 'AG',
  AI = 'AI',
  AL = 'AL',
  AM = 'AM',
  AO = 'AO',
  AQ = 'AQ',
  AR = 'AR',
  AS = 'AS',
  AT = 'AT',
  AU = 'AU',
  AW = 'AW',
  AX = 'AX',
  AZ = 'AZ',
  BA = 'BA',
  BB = 'BB',
  BD = 'BD',
  BE = 'BE',
  BF = 'BF',
  BG = 'BG',
  BH = 'BH',
  BI = 'BI',
  BJ = 'BJ',
  BL = 'BL',
  BM = 'BM',
  BN = 'BN',
  BO = 'BO',
  BQ = 'BQ',
  BR = 'BR',
  BS = 'BS',
  BT = 'BT',
  BV = 'BV',
  BW = 'BW',
  BY = 'BY',
  BZ = 'BZ',
  CA = 'CA',
  CC = 'CC',
  CD = 'CD',
  CF = 'CF',
  CG = 'CG',
  CH = 'CH',
  CI = 'CI',
  CK = 'CK',
  CL = 'CL',
  CM = 'CM',
  CN = 'CN',
  CO = 'CO',
  CR = 'CR',
  CU = 'CU',
  CV = 'CV',
  CW = 'CW',
  CX = 'CX',
  CY = 'CY',
  CZ = 'CZ',
  DE = 'DE',
  DJ = 'DJ',
  DK = 'DK',
  DM = 'DM',
  DO = 'DO',
  DZ = 'DZ',
  EC = 'EC',
  EE = 'EE',
  EG = 'EG',
  EH = 'EH',
  ER = 'ER',
  ES = 'ES',
  ET = 'ET',
  FI = 'FI',
  FJ = 'FJ',
  FK = 'FK',
  FM = 'FM',
  FO = 'FO',
  FR = 'FR',
  GA = 'GA',
  GB = 'GB',
  GD = 'GD',
  GE = 'GE',
  GF = 'GF',
  GG = 'GG',
  GH = 'GH',
  GI = 'GI',
  GL = 'GL',
  GM = 'GM',
  GN = 'GN',
  GP = 'GP',
  GQ = 'GQ',
  GR = 'GR',
  GS = 'GS',
  GT = 'GT',
  GU = 'GU',
  GW = 'GW',
  GY = 'GY',
  HK = 'HK',
  HM = 'HM',
  HN = 'HN',
  HR = 'HR',
  HT = 'HT',
  HU = 'HU',
  ID = 'ID',
  IE = 'IE',
  IL = 'IL',
  IM = 'IM',
  IN = 'IN',
  IO = 'IO',
  IQ = 'IQ',
  IR = 'IR',
  IS = 'IS',
  IT = 'IT',
  JE = 'JE',
  JM = 'JM',
  JO = 'JO',
  JP = 'JP',
  KE = 'KE',
  KG = 'KG',
  KH = 'KH',
  KI = 'KI',
  KM = 'KM',
  KN = 'KN',
  KP = 'KP',
  KR = 'KR',
  KW = 'KW',
  KY = 'KY',
  KZ = 'KZ',
  LA = 'LA',
  LB = 'LB',
  LC = 'LC',
  LI = 'LI',
  LK = 'LK',
  LR = 'LR',
  LS = 'LS',
  LT = 'LT',
  LU = 'LU',
  LV = 'LV',
  LY = 'LY',
  MA = 'MA',
  MC = 'MC',
  MD = 'MD',
  ME = 'ME',
  MF = 'MF',
  MG = 'MG',
  MH = 'MH',
  MK = 'MK',
  ML = 'ML',
  MM = 'MM',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MQ = 'MQ',
  MR = 'MR',
  MS = 'MS',
  MT = 'MT',
  MU = 'MU',
  MV = 'MV',
  MW = 'MW',
  MX = 'MX',
  MY = 'MY',
  MZ = 'MZ',
  NA = 'NA',
  NC = 'NC',
  NE = 'NE',
  NF = 'NF',
  NG = 'NG',
  NI = 'NI',
  NL = 'NL',
  NO = 'NO',
  NP = 'NP',
  NR = 'NR',
  NU = 'NU',
  NZ = 'NZ',
  OM = 'OM',
  PA = 'PA',
  PE = 'PE',
  PF = 'PF',
  PG = 'PG',
  PH = 'PH',
  PK = 'PK',
  PL = 'PL',
  PM = 'PM',
  PN = 'PN',
  PR = 'PR',
  PS = 'PS',
  PT = 'PT',
  PW = 'PW',
  PY = 'PY',
  QA = 'QA',
  RE = 'RE',
  RO = 'RO',
  RS = 'RS',
  RU = 'RU',
  RW = 'RW',
  SA = 'SA',
  SB = 'SB',
  SC = 'SC',
  SD = 'SD',
  SE = 'SE',
  SG = 'SG',
  SH = 'SH',
  SI = 'SI',
  SJ = 'SJ',
  SK = 'SK',
  SL = 'SL',
  SM = 'SM',
  SN = 'SN',
  SO = 'SO',
  SR = 'SR',
  SS = 'SS',
  ST = 'ST',
  SV = 'SV',
  SX = 'SX',
  SY = 'SY',
  SZ = 'SZ',
  TC = 'TC',
  TD = 'TD',
  TF = 'TF',
  TG = 'TG',
  TH = 'TH',
  TJ = 'TJ',
  TK = 'TK',
  TL = 'TL',
  TM = 'TM',
  TN = 'TN',
  TO = 'TO',
  TR = 'TR',
  TT = 'TT',
  TV = 'TV',
  TW = 'TW',
  TZ = 'TZ',
  UA = 'UA',
  UG = 'UG',
  UM = 'UM',
  US = 'US',
  UY = 'UY',
  UZ = 'UZ',
  VA = 'VA',
  VC = 'VC',
  VE = 'VE',
  VI = 'VI',
  VN = 'VN',
  VU = 'VU',
  WF = 'WF',
  WS = 'WS',
  XK = 'XK',
  YE = 'YE',
  YT = 'YT',
  ZA = 'ZA',
  ZM = 'ZM',
  ZW = 'ZW'
}

export type CreateAgentApplicantInput = {
  address?: InputMaybe<AgentApplicantAddressInput>;
  addressZipCode?: InputMaybe<Scalars['String']['input']>;
  coopterName?: InputMaybe<Scalars['String']['input']>;
  coverLetter?: InputMaybe<Scalars['String']['input']>;
  employmentContractSought: Array<EmploymentContract>;
  firstName: Scalars['String']['input'];
  gender: UserGender;
  informationMeetingId?: InputMaybe<Scalars['Int']['input']>;
  lastName: Scalars['String']['input'];
  personalEmail: Scalars['Email']['input'];
  phone: Scalars['String']['input'];
  resumeFile?: InputMaybe<Scalars['Upload']['input']>;
  selfEvaluationBuysAndSellsTwoVehiclesAYear: Scalars['Boolean']['input'];
  selfEvaluationCanDoProspectionAndLikesIt: Scalars['Boolean']['input'];
  selfEvaluationCanPerformComplexMechanicalOperations: Scalars['Boolean']['input'];
  selfEvaluationDoesOwnVehicleMaintenance: Scalars['Boolean']['input'];
  selfEvaluationHasAlreadyBeenSelfEmployed: Scalars['Boolean']['input'];
  selfEvaluationReadsTradePressAndRegularlyChecksCarAds: Scalars['Boolean']['input'];
  utmCampaign?: InputMaybe<Scalars['String']['input']>;
  utmMedium?: InputMaybe<Scalars['String']['input']>;
  utmSource?: InputMaybe<Scalars['String']['input']>;
  yearsAsCarSeller: YearsAs;
  yearsAsSalesperson: YearsAs;
};

export type CreateAgentPaymentAttemptInput = {
  agentApplicantId: Scalars['Int']['input'];
  amount: Scalars['Int']['input'];
  type: AgentPaymentAttemptType;
};

export type CreatePaperInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  customerId: Scalars['Int']['input'];
  kind: PaperKind;
  pic: Scalars['Upload']['input'];
  productId: Scalars['String']['input'];
  source?: InputMaybe<PaperSource>;
  status: PaperStatus;
};

export enum CreationMode {
  AUTO = 'AUTO',
  MANUAL = 'MANUAL'
}

export type Customer = {
  __typename: 'Customer';
  acceptCgu?: Maybe<Scalars['Boolean']['output']>;
  acceptPartner?: Maybe<Scalars['Boolean']['output']>;
  addressCity?: Maybe<Scalars['String']['output']>;
  addressStreet?: Maybe<Scalars['String']['output']>;
  addressZipCode?: Maybe<Scalars['String']['output']>;
  approachable: Scalars['Boolean']['output'];
  bankDetails?: Maybe<BankDetails>;
  birthday?: Maybe<Scalars['Date']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  countryOfResidence?: Maybe<CountryIsoAlpha2>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  email?: Maybe<Scalars['Email']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  locationId?: Maybe<Scalars['String']['output']>;
  myBargainings: Array<Bargaining>;
  myBookings?: Maybe<Array<Maybe<Product>>>;
  myCertificates: Array<CustomerCertificate>;
  myInvoices: Array<CustomerInvoice>;
  myOldBookings?: Maybe<Array<Maybe<Product>>>;
  myProduct?: Maybe<Product>;
  myProducts: Array<Product>;
  myQuotations?: Maybe<Array<Quotation>>;
  nationality?: Maybe<CountryIsoAlpha2>;
  phone?: Maybe<Scalars['String']['output']>;
  siret?: Maybe<Scalars['String']['output']>;
  warrantyDiscountEligible?: Maybe<Scalars['Boolean']['output']>;
};


export type CustomerBankDetailsArgs = {
  productId: Scalars['String']['input'];
};


export type CustomerMyProductArgs = {
  id: Scalars['String']['input'];
};

export type CustomerAuth = {
  __typename: 'CustomerAuth';
  customerId: Scalars['ID']['output'];
  email?: Maybe<Scalars['Email']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  token: Scalars['String']['output'];
};

export type CustomerCertificate = {
  __typename: 'CustomerCertificate';
  createdAt?: Maybe<Scalars['Date']['output']>;
  id: Scalars['Int']['output'];
  path?: Maybe<Scalars['String']['output']>;
  product: Product;
  productId: Scalars['String']['output'];
  type: CustomerCertificateType;
};

export enum CustomerCertificateType {
  BOOKING = 'BOOKING',
  PURCHASE = 'PURCHASE'
}

export type CustomerCreationInput = {
  acceptAllianz: Scalars['Boolean']['input'];
  acceptCgu: Scalars['Boolean']['input'];
  acceptPartner: Scalars['Boolean']['input'];
  addressCity?: InputMaybe<Scalars['String']['input']>;
  addressStreet?: InputMaybe<Scalars['String']['input']>;
  addressZipCode?: InputMaybe<Scalars['String']['input']>;
  approachable: Scalars['Boolean']['input'];
  birthday?: InputMaybe<Scalars['Date']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  countryOfResidence?: InputMaybe<CountryIsoAlpha2>;
  email: Scalars['Email']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<CountryIsoAlpha2>;
  password: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  siret?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerFormardedQuotation = {
  acceptCgu: Scalars['Boolean']['input'];
  acceptPartner: Scalars['Boolean']['input'];
  approachable: Scalars['Boolean']['input'];
  email: Scalars['Email']['input'];
  phone: Scalars['String']['input'];
};

export type CustomerInput = {
  acceptAllianz?: InputMaybe<Scalars['Boolean']['input']>;
  acceptCgu?: InputMaybe<Scalars['Boolean']['input']>;
  acceptPartner?: InputMaybe<Scalars['Boolean']['input']>;
  addressCity?: InputMaybe<Scalars['String']['input']>;
  addressStreet?: InputMaybe<Scalars['String']['input']>;
  addressZipCode?: InputMaybe<Scalars['String']['input']>;
  approachable?: InputMaybe<Scalars['Boolean']['input']>;
  birthday?: InputMaybe<Scalars['Date']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  countryOfResidence?: InputMaybe<CountryIsoAlpha2>;
  email?: InputMaybe<Scalars['Email']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<CountryIsoAlpha2>;
  newPassword?: InputMaybe<Scalars['String']['input']>;
  oldPassword?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  siret?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerInvoice = {
  __typename: 'CustomerInvoice';
  bankCheckFeesTaxExcluded?: Maybe<Scalars['Int']['output']>;
  bankCheckFeesTaxIncluded?: Maybe<Scalars['Int']['output']>;
  bankCheckFeesVatAmount?: Maybe<Scalars['Int']['output']>;
  billingDate: Scalars['Date']['output'];
  breakingFeesTaxExcluded?: Maybe<Scalars['Int']['output']>;
  breakingFeesTaxIncluded?: Maybe<Scalars['Int']['output']>;
  breakingFeesVatAmount?: Maybe<Scalars['Int']['output']>;
  buyerCommissionTaxExcluded?: Maybe<Scalars['Int']['output']>;
  buyerCommissionTaxIncluded?: Maybe<Scalars['Int']['output']>;
  buyerCommissionVatAmount?: Maybe<Scalars['Int']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Date']['output'];
  creationMode: CreationMode;
  customerId: Scalars['Int']['output'];
  deliveryFeesTaxExcluded?: Maybe<Scalars['Int']['output']>;
  deliveryFeesTaxIncluded?: Maybe<Scalars['Int']['output']>;
  deliveryFeesVatAmount?: Maybe<Scalars['Int']['output']>;
  file?: Maybe<Scalars['String']['output']>;
  grayCardTaxExcluded?: Maybe<Scalars['Int']['output']>;
  grayCardTaxIncluded?: Maybe<Scalars['Int']['output']>;
  grayCardVatAmount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  instantTransferTaxExcluded?: Maybe<Scalars['Int']['output']>;
  instantTransferTaxIncluded?: Maybe<Scalars['Int']['output']>;
  instantTransferVatAmount?: Maybe<Scalars['Int']['output']>;
  invoiceNumber?: Maybe<Scalars['String']['output']>;
  netSellingPriceTaxIncluded?: Maybe<Scalars['Int']['output']>;
  orderFeesTaxExcluded?: Maybe<Scalars['Int']['output']>;
  orderFeesTaxIncluded?: Maybe<Scalars['Int']['output']>;
  orderFeesVatAmount?: Maybe<Scalars['Int']['output']>;
  otherDescription?: Maybe<Scalars['String']['output']>;
  otherTaxExcluded?: Maybe<Scalars['Int']['output']>;
  otherTaxIncluded?: Maybe<Scalars['Int']['output']>;
  otherVatAmount?: Maybe<Scalars['Int']['output']>;
  payzenId?: Maybe<Scalars['Int']['output']>;
  product: Product;
  productId: Scalars['ID']['output'];
  totalTaxExcluded: Scalars['Int']['output'];
  totalTaxIncluded: Scalars['Int']['output'];
  totalVatAmount: Scalars['Int']['output'];
  updatedAt: Scalars['Date']['output'];
  warrantyDuration?: Maybe<Scalars['Int']['output']>;
  warrantyTaxExcludedNotSubjectToVat?: Maybe<Scalars['Int']['output']>;
  warrantyTaxExcludedSubjectToVat?: Maybe<Scalars['Int']['output']>;
  warrantyTaxIncluded?: Maybe<Scalars['Int']['output']>;
  warrantyType?: Maybe<Scalars['String']['output']>;
  warrantyVatAmount?: Maybe<Scalars['Int']['output']>;
};

export type CustomerReview = {
  __typename: 'CustomerReview';
  comment?: Maybe<Scalars['String']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  productId?: Maybe<Scalars['String']['output']>;
  rating?: Maybe<Scalars['Int']['output']>;
};

export type CustomerReviewInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  productId: Scalars['String']['input'];
  rating: Scalars['Int']['input'];
};

export type CustomerTracking = {
  __typename: 'CustomerTracking';
  customerId: Customer;
  date: Scalars['Date']['output'];
  id: Scalars['Int']['output'];
  metadata?: Maybe<Scalars['JSON']['output']>;
  type: Scalars['String']['output'];
};

export type CustomerTrackingCreateResponse = {
  __typename: 'CustomerTrackingCreateResponse';
  eventId: Scalars['Int']['output'];
};

export type CustomerTrackingInput = {
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  type: Scalars['String']['input'];
};

export type DecreasePriceInput = {
  price?: InputMaybe<Scalars['Int']['input']>;
  utmCampaign?: InputMaybe<Scalars['String']['input']>;
  utmContent?: InputMaybe<Scalars['String']['input']>;
  utmMedium?: InputMaybe<Scalars['String']['input']>;
  utmSource?: InputMaybe<Scalars['String']['input']>;
};

export type Defect = {
  __typename: 'Defect';
  comment?: Maybe<Scalars['String']['output']>;
  descriptions?: Maybe<Array<Maybe<DefectDescription>>>;
  id: Scalars['ID']['output'];
  kind?: Maybe<Scalars['String']['output']>;
  pic: Scalars['String']['output'];
  productId: Scalars['String']['output'];
  px: Scalars['Float']['output'];
  py: Scalars['Float']['output'];
  schema: DefectSchema;
  size?: Maybe<Scalars['String']['output']>;
  version: Scalars['String']['output'];
};

export type DefectDescription = {
  __typename: 'DefectDescription';
  detail?: Maybe<Scalars['String']['output']>;
  kind: Scalars['String']['output'];
  size: DefectSize;
};

export enum DefectSchema {
  EXTERIORS = 'EXTERIORS',
  EXTERIOR_BACK = 'EXTERIOR_BACK',
  EXTERIOR_FRONT = 'EXTERIOR_FRONT',
  EXTERIOR_LEFT = 'EXTERIOR_LEFT',
  EXTERIOR_RIGHT = 'EXTERIOR_RIGHT',
  EXTERIOR_TOP = 'EXTERIOR_TOP',
  INTERIORS = 'INTERIORS'
}

export enum DefectSize {
  INF_1_CM = 'INF_1_CM',
  INF_2_CM = 'INF_2_CM',
  INF_3_CM = 'INF_3_CM',
  INF_4_CM = 'INF_4_CM',
  INF_5_CM = 'INF_5_CM',
  INF_5_MM = 'INF_5_MM',
  INF_7_CM = 'INF_7_CM',
  INF_10_CM = 'INF_10_CM',
  ON_10_CM = 'ON_10_CM',
  ON_20_CM = 'ON_20_CM',
  ON_30_CM = 'ON_30_CM'
}

export type DeletePaperInput = {
  id: Scalars['ID']['input'];
  productId: Scalars['String']['input'];
};

export type Department = {
  __typename: 'Department';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type Detail = {
  __typename: 'Detail';
  batteryCapacity?: Maybe<Scalars['Int']['output']>;
  batteryIsRental?: Maybe<Scalars['Boolean']['output']>;
  batteryRentalPrice?: Maybe<Scalars['Int']['output']>;
  car: CarInfos;
  carId?: Maybe<Scalars['Int']['output']>;
  co2Emissions?: Maybe<Scalars['Int']['output']>;
  critAir: Scalars['Int']['output'];
  doorsCount?: Maybe<Scalars['Int']['output']>;
  entryIntoServiceDate: Scalars['Date']['output'];
  exteriorColor: Scalars['String']['output'];
  firstHand?: Maybe<Scalars['Boolean']['output']>;
  fiscalPower?: Maybe<Scalars['Int']['output']>;
  fromCountry?: Maybe<Scalars['String']['output']>;
  gearsNumber: Scalars['Int']['output'];
  hasConsistentSerialCg: Scalars['Boolean']['output'];
  hasConstructorWarranty: Scalars['Boolean']['output'];
  hasDuplicateKeys: Scalars['Boolean']['output'];
  hasTwoPeopleOnCg: Scalars['Boolean']['output'];
  histovecInformations?: Maybe<Scalars['String']['output']>;
  histovecReportUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  interiorColor: Scalars['String']['output'];
  interiorMaterial: Scalars['String']['output'];
  isBig: Scalars['Boolean']['output'];
  isExpensive: Scalars['Boolean']['output'];
  isFourWheelDrive: Scalars['String']['output'];
  isImported?: Maybe<Scalars['Boolean']['output']>;
  isLoa: Scalars['Boolean']['output'];
  isPledged: Scalars['Boolean']['output'];
  lastTechnicalInspectionDate?: Maybe<Scalars['Date']['output']>;
  licensePlate: Scalars['String']['output'];
  maintenanceNotation?: Maybe<Scalars['Float']['output']>;
  mileage: Scalars['Int']['output'];
  ownerManual?: Maybe<Scalars['String']['output']>;
  ownerManualBills?: Maybe<Scalars['String']['output']>;
  productId: Scalars['String']['output'];
  recoverableVat?: Maybe<Scalars['Boolean']['output']>;
  saleReason?: Maybe<Scalars['String']['output']>;
  seatsNumber?: Maybe<Scalars['Int']['output']>;
  spareWheel: Scalars['String']['output'];
  vehiculeIdentificationNumber?: Maybe<Scalars['String']['output']>;
  warrantyExpirationDate?: Maybe<Scalars['Date']['output']>;
};

export enum DistributionKind {
  BELT = 'BELT',
  CHAIN = 'CHAIN',
  NONE = 'NONE'
}

export enum DistributionMethod {
  FREE_INPUT = 'FREE_INPUT',
  MANUAL = 'MANUAL',
  NETWORKING_ADVISOR = 'NETWORKING_ADVISOR',
  SCORING_BASED = 'SCORING_BASED',
  TRACKED_LINK = 'TRACKED_LINK'
}

export enum EmploymentContract {
  CDI = 'CDI',
  INTERNSHIP = 'INTERNSHIP',
  SELF_EMPLOYED = 'SELF_EMPLOYED',
  WORK_STUDY = 'WORK_STUDY'
}

export enum Energy {
  BIOFUEL = 'BIOFUEL',
  DIESEL = 'DIESEL',
  DUAL_FUEL_PETROL_BIOETHANOL = 'DUAL_FUEL_PETROL_BIOETHANOL',
  DUAL_FUEL_PETROL_GNV = 'DUAL_FUEL_PETROL_GNV',
  DUAL_FUEL_PETROL_GPL = 'DUAL_FUEL_PETROL_GPL',
  ELECTRIC = 'ELECTRIC',
  HYBRID_DIESEL = 'HYBRID_DIESEL',
  HYBRID_GAS = 'HYBRID_GAS',
  HYBRID_PETROL = 'HYBRID_PETROL',
  HYBRID_PLUG_IN = 'HYBRID_PLUG_IN',
  HYDROGEN = 'HYDROGEN',
  OTHER = 'OTHER',
  PETROL = 'PETROL'
}

export type EvaluatePriceEstimationUpateResult = {
  __typename: 'EvaluatePriceEstimationUpateResult';
  id: Scalars['Int']['output'];
  priceEstimationEvaluation: PriceEstimationEvaluation;
};

export type Exterior = {
  __typename: 'Exterior';
  blinkersState?: Maybe<Rating>;
  brakeLightsState?: Maybe<Rating>;
  comment?: Maybe<Scalars['String']['output']>;
  doorsOpeningState?: Maybe<Rating>;
  exteriorNotation: Scalars['Float']['output'];
  headLightsState?: Maybe<Rating>;
  id: Scalars['ID']['output'];
  plateLightingState?: Maybe<Rating>;
  productId: Scalars['String']['output'];
  rearLightsState?: Maybe<Rating>;
  sideLightsState?: Maybe<Rating>;
  trunkOpeningState?: Maybe<Rating>;
  wipersState?: Maybe<Rating>;
};

export type FavoriteProductView = {
  __typename: 'FavoriteProductView';
  addedByUserAt: Scalars['Date']['output'];
  productId: Scalars['String']['output'];
};

export type FundsPayin = {
  __typename: 'FundsPayin';
  createdAt: Scalars['Date']['output'];
  creditedFunds: Scalars['Int']['output'];
  currency: Scalars['String']['output'];
  debitedFunds: Scalars['Int']['output'];
  declaredDebitedFunds?: Maybe<Scalars['Int']['output']>;
  declaredFees?: Maybe<Scalars['Int']['output']>;
  executionDate: Scalars['Date']['output'];
  fees: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  tags?: Maybe<Scalars['String']['output']>;
  wireReference?: Maybe<Scalars['String']['output']>;
};

export type FundsPayout = {
  __typename: 'FundsPayout';
  createdAt: Scalars['Date']['output'];
  creditedBankAccount?: Maybe<Scalars['JSON']['output']>;
  creditedFunds: Scalars['Int']['output'];
  currency: Scalars['String']['output'];
  executionDate: Scalars['Date']['output'];
  fees: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  isInstantTransfer: Scalars['Boolean']['output'];
  tags?: Maybe<Scalars['String']['output']>;
  wireReference?: Maybe<Scalars['String']['output']>;
};

export enum Gearbox {
  AUTOMATIC = 'AUTOMATIC',
  MANUAL = 'MANUAL',
  ROBOTIC = 'ROBOTIC'
}

export type GrayCard = Transaction & {
  __typename: 'GrayCard';
  amount: Scalars['Int']['output'];
  createdAt: Scalars['Date']['output'];
  error?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  source?: Maybe<TransactionSource>;
  status: Status;
  updatedAt: Scalars['Date']['output'];
};

export type HitNumberByDay = {
  __typename: 'HitNumberByDay';
  day: Scalars['Date']['output'];
  number: Scalars['Int']['output'];
};

export type InformationMeeting = {
  __typename: 'InformationMeeting';
  createdAt: Scalars['Date']['output'];
  id: Scalars['Int']['output'];
  meetingDate: Scalars['Date']['output'];
  updatedAt: Scalars['Date']['output'];
};

export type Inspector = {
  __typename: 'Inspector';
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  pic?: Maybe<Scalars['String']['output']>;
};

export type Interior = {
  __typename: 'Interior';
  absLight?: Maybe<Rating>;
  airbagLight?: Maybe<Rating>;
  brakePadLight?: Maybe<Rating>;
  centralLocking?: Maybe<Rating>;
  comment?: Maybe<Scalars['String']['output']>;
  electricWindows?: Maybe<Rating>;
  engineLight?: Maybe<Rating>;
  exhaustSystemLight?: Maybe<Rating>;
  gloveBoxState?: Maybe<Rating>;
  id: Scalars['ID']['output'];
  indoorLightingState?: Maybe<Rating>;
  interiorNotation: Scalars['Float']['output'];
  klaxonState?: Maybe<Rating>;
  oilLevelLight?: Maybe<Rating>;
  otherWarningLight?: Maybe<Rating>;
  productId: Scalars['String']['output'];
  radioState?: Maybe<Rating>;
  seatsCarAdjustingState?: Maybe<Rating>;
};

export enum InternalStatus {
  BREAKING_ABANDONED = 'BREAKING_ABANDONED',
  BREAKING_AFTER_PAID = 'BREAKING_AFTER_PAID',
  BREAKING_AFTER_UNPAID = 'BREAKING_AFTER_UNPAID',
  BREAKING_BEFORE_PAID = 'BREAKING_BEFORE_PAID',
  BREAKING_BEFORE_UNPAID = 'BREAKING_BEFORE_UNPAID',
  CONTRACT_NOT_SIGNED = 'CONTRACT_NOT_SIGNED',
  END_OF_CONTRACT = 'END_OF_CONTRACT',
  ERROR = 'ERROR',
  FOR_SALE = 'FOR_SALE',
  INSPECTION_CANCELLED = 'INSPECTION_CANCELLED',
  INSPECTION_SCHEDULED = 'INSPECTION_SCHEDULED',
  LISTING_ABANDONED = 'LISTING_ABANDONED',
  ON_HOLD = 'ON_HOLD',
  PRE_RESERVED = 'PRE_RESERVED',
  RESERVED = 'RESERVED',
  SOLD_BY_KYUMP = 'SOLD_BY_KYUMP',
  TO_PUT_ONLINE = 'TO_PUT_ONLINE'
}

export type Maintenance = {
  __typename: 'Maintenance';
  comment?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['Date']['output']>;
  id: Scalars['Int']['output'];
  kinds: Array<MaintenanceKind>;
  km?: Maybe<Scalars['Int']['output']>;
  pic?: Maybe<Scalars['String']['output']>;
  productId: Scalars['String']['output'];
};

export enum MaintenanceKind {
  AIR_CONDITIONER_CHARGING = 'AIR_CONDITIONER_CHARGING',
  AIR_FILTER = 'AIR_FILTER',
  BACK_BRAKES_DISK = 'BACK_BRAKES_DISK',
  BACK_REAR_BRAKES = 'BACK_REAR_BRAKES',
  BACK_TIRES_CHANGE = 'BACK_TIRES_CHANGE',
  BRAKES_LIQUID_REPLACEMENT = 'BRAKES_LIQUID_REPLACEMENT',
  CABIN_FILTER = 'CABIN_FILTER',
  DISTRIBUTION_BELT = 'DISTRIBUTION_BELT',
  ENERGY_FILTER = 'ENERGY_FILTER',
  FRONT_BRAKES_DISK = 'FRONT_BRAKES_DISK',
  FRONT_REAR_BRAKES = 'FRONT_REAR_BRAKES',
  FRONT_TIRES_CHANGE = 'FRONT_TIRES_CHANGE',
  GEARBOX_DRAINING = 'GEARBOX_DRAINING',
  GEOMETRY_ADJUSTMENT = 'GEOMETRY_ADJUSTMENT',
  MANUFACTURER_INVOICE = 'MANUFACTURER_INVOICE',
  OTHER = 'OTHER',
  REV = 'REV',
  SPARK_PLUGS = 'SPARK_PLUGS'
}

export type Mechanic = {
  __typename: 'Mechanic';
  alternatorChargeCapacity?: Maybe<Rating>;
  batteryVoltagePowerOff?: Maybe<Scalars['Float']['output']>;
  batteryVoltagePowerOn?: Maybe<Scalars['Float']['output']>;
  beltState?: Maybe<Rating>;
  brakeFluidLevel?: Maybe<Rating>;
  brakesWaterLevel?: Maybe<Scalars['Int']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  coolingLiquidLevel?: Maybe<Rating>;
  distributionKind: DistributionKind;
  expectedBeltChangeDate?: Maybe<Scalars['Date']['output']>;
  expectedBeltChangeKm?: Maybe<Scalars['Int']['output']>;
  expectedNextInspectionDate?: Maybe<Scalars['Date']['output']>;
  expectedNextInspectionKm?: Maybe<Scalars['Int']['output']>;
  freezingPointLevel?: Maybe<Scalars['Int']['output']>;
  hasNoOilLeak: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  oilLevel?: Maybe<Rating>;
  productId: Scalars['String']['output'];
};

export type Mutation = {
  __typename: 'Mutation';
  acceptOffer: Bargaining;
  acceptPriceDrop: Bargaining;
  addFavoriteProduct: Scalars['String']['output'];
  agentApplicantEnrollment?: Maybe<Scalars['Boolean']['output']>;
  agentApplicantInformationMeetingRegistration?: Maybe<Scalars['Boolean']['output']>;
  buyerMessage?: Maybe<Scalars['Boolean']['output']>;
  buyerNegotiation?: Maybe<Scalars['Boolean']['output']>;
  cancelAppointment?: Maybe<Appointment>;
  confirmEmail?: Maybe<CustomerAuth>;
  contactMessage?: Maybe<Scalars['Boolean']['output']>;
  createAgentApplicant: AgentApplicantCreationResult;
  createAgentPaymentAttempt: AgentPaymentAttempt;
  createAlert: Alert;
  createAppointmentAvailability?: Maybe<AppointmentAvailabilities>;
  createBargaining: Bargaining;
  createCart: Cart;
  createCustomer: CustomerAuth;
  createPaper?: Maybe<Paper>;
  createProductHit?: Maybe<ProductHit>;
  createTransaction?: Maybe<TransactionInfo>;
  customerReview?: Maybe<CustomerReview>;
  customerTracking?: Maybe<CustomerTrackingCreateResponse>;
  decreasePrice?: Maybe<Product>;
  deleteAppointmentAvailability?: Maybe<AppointmentAvailabilities>;
  deletePaper?: Maybe<Scalars['Boolean']['output']>;
  evaluatePriceEstimation: EvaluatePriceEstimationUpateResult;
  forwardQuotation: Quotation;
  initializeQuotation: Quotation;
  manageQuotationAfterResetPassword?: Maybe<Quotation>;
  refreshQuotation?: Maybe<Quotation>;
  refuseAndCounterOffer: Bargaining;
  refuseOffer: Bargaining;
  removeFavoriteProduct: Scalars['String']['output'];
  reportQuotation: Quotation;
  resetPassword?: Maybe<Scalars['String']['output']>;
  sendConfirmEmail: Scalars['Boolean']['output'];
  setClickedBookingCtaType: Scalars['Boolean']['output'];
  setCustomerPriceEstimation: SetCustomerPriceEstimationResult;
  shareProduct: Share;
  updateAgentApplicantEnrollmentInfo: AgentApplicant;
  updateAlert: Alert;
  updateAppointmentAvailability?: Maybe<AppointmentAvailabilities>;
  updateCart: Cart;
  updateConfirmation?: Maybe<Confirmation>;
  updateCustomer: Customer;
  updatePaper?: Maybe<Paper>;
  updatePassword?: Maybe<Customer>;
  updateQuotation: Quotation;
  uploadPapers?: Maybe<Array<Maybe<Paper>>>;
  validateQuotation?: Maybe<Quotation>;
};


export type MutationAcceptOfferArgs = {
  offerId: Scalars['ID']['input'];
};


export type MutationAcceptPriceDropArgs = {
  offerId: Scalars['ID']['input'];
};


export type MutationAddFavoriteProductArgs = {
  favoriteListId: Scalars['UUID']['input'];
  productId: Scalars['String']['input'];
};


export type MutationAgentApplicantEnrollmentArgs = {
  agentApplicantId: Scalars['Int']['input'];
  input: AgentApplicantEnrollmentInput;
};


export type MutationAgentApplicantInformationMeetingRegistrationArgs = {
  agentApplicantId: Scalars['Int']['input'];
  informationMeetingId: Scalars['Int']['input'];
};


export type MutationBuyerMessageArgs = {
  message: ProductMessageInput;
  productId: Scalars['String']['input'];
  source: Scalars['String']['input'];
};


export type MutationBuyerNegotiationArgs = {
  message: ProductNegotiationInput;
  productId: Scalars['String']['input'];
  source: Scalars['String']['input'];
};


export type MutationCancelAppointmentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationConfirmEmailArgs = {
  hash: Scalars['String']['input'];
  id: Scalars['Int']['input'];
};


export type MutationContactMessageArgs = {
  message: ContactMessageInput;
};


export type MutationCreateAgentApplicantArgs = {
  agentApplicant: CreateAgentApplicantInput;
};


export type MutationCreateAgentPaymentAttemptArgs = {
  input: CreateAgentPaymentAttemptInput;
};


export type MutationCreateAlertArgs = {
  alert: AlertInput;
  tracker?: InputMaybe<Tracker>;
};


export type MutationCreateAppointmentAvailabilityArgs = {
  address: AddressInput;
  quotationId: Scalars['ID']['input'];
};


export type MutationCreateBargainingArgs = {
  bargaining: BargainingInput;
};


export type MutationCreateCartArgs = {
  cart: CartCreationInput;
  tracker?: InputMaybe<Tracker>;
};


export type MutationCreateCustomerArgs = {
  customer: CustomerCreationInput;
};


export type MutationCreatePaperArgs = {
  paper: CreatePaperInput;
};


export type MutationCreateProductHitArgs = {
  productHit?: InputMaybe<ProductHitInput>;
};


export type MutationCreateTransactionArgs = {
  cartId: Scalars['Int']['input'];
  productId: Scalars['String']['input'];
};


export type MutationCustomerReviewArgs = {
  customerReview: CustomerReviewInput;
};


export type MutationCustomerTrackingArgs = {
  trackingEvent: CustomerTrackingInput;
};


export type MutationDecreasePriceArgs = {
  id: Scalars['String']['input'];
  update: DecreasePriceInput;
};


export type MutationDeleteAppointmentAvailabilityArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeletePaperArgs = {
  paper: DeletePaperInput;
};


export type MutationEvaluatePriceEstimationArgs = {
  evaluation: PriceEstimationEvaluation;
  quotationId: Scalars['ID']['input'];
};


export type MutationForwardQuotationArgs = {
  customer: CustomerFormardedQuotation;
  id: Scalars['ID']['input'];
};


export type MutationInitializeQuotationArgs = {
  tracker?: InputMaybe<Tracker>;
};


export type MutationManageQuotationAfterResetPasswordArgs = {
  quotationId: Scalars['ID']['input'];
};


export type MutationRefreshQuotationArgs = {
  quotationId: Scalars['ID']['input'];
  updates: QuotationRefreshInput;
};


export type MutationRefuseAndCounterOfferArgs = {
  acceptPriceDrop?: InputMaybe<Scalars['Boolean']['input']>;
  amount: Scalars['Int']['input'];
  offerId: Scalars['ID']['input'];
};


export type MutationRefuseOfferArgs = {
  offerId: Scalars['ID']['input'];
};


export type MutationRemoveFavoriteProductArgs = {
  favoriteListId: Scalars['UUID']['input'];
  productId: Scalars['String']['input'];
};


export type MutationReportQuotationArgs = {
  quotationId: Scalars['ID']['input'];
  wrongFields: Array<QuotationField>;
};


export type MutationResetPasswordArgs = {
  email?: InputMaybe<Scalars['Email']['input']>;
  redirectionUrl?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSendConfirmEmailArgs = {
  customerId: Scalars['Int']['input'];
};


export type MutationSetClickedBookingCtaTypeArgs = {
  ctaType: PriceEstimationEvaluation;
  quotationId: Scalars['ID']['input'];
};


export type MutationSetCustomerPriceEstimationArgs = {
  price: Scalars['Int']['input'];
  quotationId: Scalars['ID']['input'];
};


export type MutationShareProductArgs = {
  share: ShareInput;
};


export type MutationUpdateAgentApplicantEnrollmentInfoArgs = {
  agentApplicantId: Scalars['Int']['input'];
  input: UpdateAgentApplicantEnrollmentInput;
};


export type MutationUpdateAlertArgs = {
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['Email']['input'];
  id: Scalars['Int']['input'];
};


export type MutationUpdateAppointmentAvailabilityArgs = {
  id: Scalars['ID']['input'];
  rank: AvailabilityRank;
  slot: AvailabilitySlotInput;
};


export type MutationUpdateCartArgs = {
  cart: CartInput;
  productId: Scalars['String']['input'];
};


export type MutationUpdateConfirmationArgs = {
  confirmation?: InputMaybe<UpdateConfirmationInput>;
};


export type MutationUpdateCustomerArgs = {
  customer: CustomerInput;
};


export type MutationUpdatePaperArgs = {
  paper: UpdatePaperInput;
};


export type MutationUpdatePasswordArgs = {
  customerId?: InputMaybe<Scalars['Int']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateQuotationArgs = {
  id: Scalars['ID']['input'];
  update: QuotationInput;
};


export type MutationUploadPapersArgs = {
  papers: Array<CreatePaperInput>;
  productId: Scalars['String']['input'];
};


export type MutationValidateQuotationArgs = {
  quotationId: Scalars['ID']['input'];
};

export type OldBookingInfosPayment = {
  __typename: 'OldBookingInfosPayment';
  amount: Scalars['Int']['output'];
  received: Scalars['Boolean']['output'];
  type?: Maybe<TransactionSource>;
};

export type Option = {
  __typename: 'Option';
  airConditioner?: Maybe<Rating>;
  alloyWheels: Scalars['Boolean']['output'];
  automaticHeadlights?: Maybe<Rating>;
  automaticWipers?: Maybe<Rating>;
  blindSpotAssist?: Maybe<Rating>;
  bluetooth?: Maybe<Rating>;
  comment?: Maybe<Scalars['String']['output']>;
  customOptions?: Maybe<Scalars['String']['output']>;
  electricallyFoldableRearViewMirrors?: Maybe<Rating>;
  frontRadar?: Maybe<Rating>;
  gps?: Maybe<Rating>;
  headUpDisplay?: Maybe<Rating>;
  heatedSeats?: Maybe<Rating>;
  hitch: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  ledDippedBeamAndMainBeam?: Maybe<Rating>;
  metallicPaint: Scalars['Boolean']['output'];
  openableSunroof?: Maybe<Rating>;
  panoramicSunroof?: Maybe<Rating>;
  parkAssist?: Maybe<Rating>;
  powerLiftgate?: Maybe<Rating>;
  powerMirrors?: Maybe<Rating>;
  powerSeats?: Maybe<Rating>;
  productId: Scalars['String']['output'];
  rearRadar?: Maybe<Rating>;
  rearViewCamera?: Maybe<Rating>;
  regulator?: Maybe<Rating>;
  speedLimiter?: Maybe<Rating>;
  usbAudioConnector?: Maybe<Rating>;
  xenonHeadlights: Scalars['Boolean']['output'];
};

export type Order = {
  __typename: 'Order';
  id: Scalars['ID']['output'];
  status: Status;
};

export type Owner = {
  __typename: 'Owner';
  buyFrom?: Maybe<BuyFrom>;
  country?: Maybe<Scalars['String']['output']>;
  km?: Maybe<Scalars['Int']['output']>;
  purchaseCountry?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['Date']['output']>;
};

export type OwnerHistories = {
  __typename: 'OwnerHistories';
  comment?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  notation?: Maybe<Scalars['Float']['output']>;
  owners?: Maybe<Array<Maybe<Owner>>>;
  ownersNumber?: Maybe<OwnerNumber>;
  productId: Scalars['String']['output'];
};

export enum OwnerNumber {
  FIRST_HAND = 'FIRST_HAND',
  SECOND_HAND = 'SECOND_HAND',
  THIRD_HAND_OR_MORE = 'THIRD_HAND_OR_MORE'
}

export type Pagination = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type Paper = {
  __typename: 'Paper';
  comment?: Maybe<Scalars['String']['output']>;
  customerId?: Maybe<Scalars['Int']['output']>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  kind: PaperKind;
  pic: Scalars['String']['output'];
  productId: Scalars['String']['output'];
  source: PaperSource;
  status: PaperStatus;
  validatedAt?: Maybe<Scalars['Date']['output']>;
};

export enum PaperKind {
  BANKERS_CHECK_SELLER = 'BANKERS_CHECK_SELLER',
  BANK_DETAILS = 'BANK_DETAILS',
  BANK_DETAILS_BUYER = 'BANK_DETAILS_BUYER',
  BOOKING_PAIMENT_PROOF = 'BOOKING_PAIMENT_PROOF',
  BUYER_INFORMATION_DOCUMENT = 'BUYER_INFORMATION_DOCUMENT',
  CAPCAR_LOA_CONTRACT = 'CAPCAR_LOA_CONTRACT',
  CERFA_NOT_SIGNED = 'CERFA_NOT_SIGNED',
  CERFA_SIGNED = 'CERFA_SIGNED',
  CERTIFICATE_NO_PLEDGE = 'CERTIFICATE_NO_PLEDGE',
  CLIENT_CONTRACT = 'CLIENT_CONTRACT',
  CLIENT_CONTRACT_PROOF = 'CLIENT_CONTRACT_PROOF',
  CLIENT_SIGNATURE = 'CLIENT_SIGNATURE',
  DUPLICATE_KEYS = 'DUPLICATE_KEYS',
  GRAY_CARD = 'GRAY_CARD',
  GRAY_CARD_STROCKED = 'GRAY_CARD_STROCKED',
  HISTOVEC = 'HISTOVEC',
  IDENTITY_CARD = 'IDENTITY_CARD',
  IDENTITY_CARD_BUYER = 'IDENTITY_CARD_BUYER',
  IDENTITY_CARD_CO_OWNER = 'IDENTITY_CARD_CO_OWNER',
  INHERITANCE_DOCUMENT = 'INHERITANCE_DOCUMENT',
  KBIS_BUYER = 'KBIS_BUYER',
  KBIS_SELLER = 'KBIS_SELLER',
  LOA_BALANCE = 'LOA_BALANCE',
  LOA_CONTRACT = 'LOA_CONTRACT',
  OTHER = 'OTHER',
  PAYMENT_DISCLAIMER = 'PAYMENT_DISCLAIMER',
  POST_TRANSACTION = 'POST_TRANSACTION',
  PROXY = 'PROXY',
  PURCHASE_DECLARATION = 'PURCHASE_DECLARATION',
  SALE_CERTIFICATE_TO_THIRD_PARTY_SIGNED = 'SALE_CERTIFICATE_TO_THIRD_PARTY_SIGNED',
  SERIAL_NUMBER = 'SERIAL_NUMBER',
  TECHNICAL_INSPECTION = 'TECHNICAL_INSPECTION',
  TRANSFER_CAPCAR = 'TRANSFER_CAPCAR',
  TRANSFER_CONTRACT = 'TRANSFER_CONTRACT',
  TRANSFER_MANDATE = 'TRANSFER_MANDATE',
  VIN = 'VIN',
  VOUCHER_SIGNED = 'VOUCHER_SIGNED',
  WARRANTY = 'WARRANTY'
}

export enum PaperSource {
  BUYER = 'BUYER',
  INSPECTION = 'INSPECTION',
  KRONOS = 'KRONOS',
  SELLER = 'SELLER',
  UNKNOWN = 'UNKNOWN'
}

export enum PaperStatus {
  REFUSED = 'REFUSED',
  VALIDATED = 'VALIDATED',
  WAITING = 'WAITING'
}

export type PartnerSuggestion = {
  __typename: 'PartnerSuggestion';
  CO2?: Maybe<Scalars['Int']['output']>;
  adminPower?: Maybe<Scalars['Int']['output']>;
  brand?: Maybe<Scalars['String']['output']>;
  carBody?: Maybe<Scalars['String']['output']>;
  cnit?: Maybe<Scalars['String']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  cylindre?: Maybe<Scalars['Int']['output']>;
  dateSIV?: Maybe<Scalars['Date']['output']>;
  doors?: Maybe<Scalars['Int']['output']>;
  emptyWeight?: Maybe<Scalars['Int']['output']>;
  energie?: Maybe<Scalars['String']['output']>;
  genre?: Maybe<Scalars['String']['output']>;
  immat?: Maybe<Scalars['String']['output']>;
  kwPower?: Maybe<Scalars['Int']['output']>;
  mec?: Maybe<Scalars['Date']['output']>;
  ptacsiv?: Maybe<Scalars['Int']['output']>;
  ptrsiv?: Maybe<Scalars['Int']['output']>;
  puisDin?: Maybe<Scalars['Int']['output']>;
  seats?: Maybe<Scalars['Int']['output']>;
  sivCarBody?: Maybe<Scalars['String']['output']>;
  sivGenre?: Maybe<Scalars['String']['output']>;
  sivVersion?: Maybe<Scalars['String']['output']>;
  tvv?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['String']['output']>;
  vin?: Maybe<Scalars['String']['output']>;
};

export type Picture = {
  __typename: 'Picture';
  index: Scalars['Int']['output'];
  kind: Scalars['String']['output'];
  pic: Scalars['String']['output'];
  productId: Scalars['String']['output'];
};

export enum Position {
  FRONT_LEFT = 'FRONT_LEFT',
  FRONT_RIGHT = 'FRONT_RIGHT',
  REAR_LEFT = 'REAR_LEFT',
  REAR_RIGHT = 'REAR_RIGHT'
}

export enum PositionBrake {
  FRONT = 'FRONT',
  REAR = 'REAR'
}

export type PriceCut = {
  __typename: 'PriceCut';
  detail?: Maybe<PriceCutDetails>;
  fixed: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  operation?: Maybe<Scalars['String']['output']>;
  piece?: Maybe<PriceCutPieces>;
  price: Scalars['Int']['output'];
  productId: Scalars['String']['output'];
  type?: Maybe<PriceCutType>;
};

export enum PriceCutDetails {
  AUTOMATIC = 'AUTOMATIC',
  BACK = 'BACK',
  BRAKE_FLUID = 'BRAKE_FLUID',
  COOLANT = 'COOLANT',
  FRONT = 'FRONT',
  MANUAL = 'MANUAL',
  MULTIMEDIA = 'MULTIMEDIA',
  PROFESSIONAL = 'PROFESSIONAL',
  REVERSING_RADAR = 'REVERSING_RADAR',
  SIMPLE = 'SIMPLE',
  WINDOW_REGULATOR = 'WINDOW_REGULATOR',
  WINDSHIELD_WASHER = 'WINDSHIELD_WASHER'
}

export enum PriceCutPieces {
  AIR_CONDITIONING_REFILL = 'AIR_CONDITIONING_REFILL',
  AT_THE_MANUFACTURER = 'AT_THE_MANUFACTURER',
  BACK_LIGHTS = 'BACK_LIGHTS',
  BODY = 'BODY',
  BRAKE_DISKS = 'BRAKE_DISKS',
  BRAKE_PADS = 'BRAKE_PADS',
  BULBS = 'BULBS',
  CAR_BATTERY = 'CAR_BATTERY',
  DRAIN_AND_ALL_FILTERS = 'DRAIN_AND_ALL_FILTERS',
  DRAIN_AND_OIL_FILTER = 'DRAIN_AND_OIL_FILTER',
  DRIVE_AXLE = 'DRIVE_AXLE',
  DUPLICATE_KEYS = 'DUPLICATE_KEYS',
  ELECTRICAL_ELEMENT = 'ELECTRICAL_ELEMENT',
  ENGINE = 'ENGINE',
  EXTERIOR = 'EXTERIOR',
  FRONT_LIGHTS = 'FRONT_LIGHTS',
  GEAR_BOX = 'GEAR_BOX',
  GRAY_CARD = 'GRAY_CARD',
  INTERIOR = 'INTERIOR',
  LIQUID = 'LIQUID',
  MAINTENANCE_HISTORY = 'MAINTENANCE_HISTORY',
  SHOCK_ABSORBERS = 'SHOCK_ABSORBERS',
  TECHNICAL_INSPECTION = 'TECHNICAL_INSPECTION',
  TIRES = 'TIRES',
  WINDSHIELD = 'WINDSHIELD'
}

export enum PriceCutType {
  CLEANING = 'CLEANING',
  CONTROL = 'CONTROL',
  DOCUMENTS_TO_PROVIDE = 'DOCUMENTS_TO_PROVIDE',
  OTHER = 'OTHER',
  REPAIR = 'REPAIR',
  REPLACEMENT = 'REPLACEMENT',
  REVISION = 'REVISION'
}

export enum PriceEstimationEvaluation {
  /** the user has chosen the 'I like' option */
  APPRECIATED = 'APPRECIATED',
  /** the user has chosen the 'I dislike' option */
  DISLIKED = 'DISLIKED',
  /** default value, the user has not chosen any option yet */
  DROP = 'DROP',
  /** the user has chosen the 'no opinion' option */
  NO_OPTION = 'NO_OPTION'
}

export type PriceUpdate = {
  __typename: 'PriceUpdate';
  date: Scalars['Date']['output'];
  fromCustomer: Scalars['Boolean']['output'];
  initialPrice: Scalars['Int']['output'];
  newPrice: Scalars['Int']['output'];
};

export type Product = {
  __typename: 'Product';
  ads: Array<Ad>;
  advisor?: Maybe<Advisor>;
  agent?: Maybe<Agent>;
  bargainings: Array<Maybe<Bargaining>>;
  brakes: Array<Brake>;
  capcarViewsIn72hrs: Scalars['Int']['output'];
  carOptions: Array<CarOption>;
  commission: Scalars['Int']['output'];
  contract?: Maybe<Paper>;
  createdAt: Scalars['Date']['output'];
  defects: Array<Defect>;
  department?: Maybe<Department>;
  detail: Detail;
  examinationDate?: Maybe<Scalars['Date']['output']>;
  exclusivityEndDate: Scalars['Date']['output'];
  exterior?: Maybe<Exterior>;
  fundsPayins: Array<FundsPayin>;
  fundsPayouts: Array<FundsPayout>;
  hasVideo: Scalars['Boolean']['output'];
  hitNumberByDay?: Maybe<Array<Maybe<HitNumberByDay>>>;
  id: Scalars['ID']['output'];
  inspection?: Maybe<Appointment>;
  inspector?: Maybe<Inspector>;
  interior?: Maybe<Interior>;
  internalStatus: InternalStatus;
  isMyProduct: Scalars['Boolean']['output'];
  isRepublishedBefore72hrs: Scalars['Boolean']['output'];
  lastPriceDropByCustomer?: Maybe<PriceUpdate>;
  maintenances: Array<Maintenance>;
  mechanic?: Maybe<Mechanic>;
  myLastBuyerBargaining?: Maybe<Bargaining>;
  option?: Maybe<Option>;
  order?: Maybe<Order>;
  outgoingAgentSlice?: Maybe<Scalars['Int']['output']>;
  ownerHistory?: Maybe<OwnerHistories>;
  papers?: Maybe<Array<Maybe<Paper>>>;
  pictures: Array<Picture>;
  price: Scalars['Int']['output'];
  priceArgus?: Maybe<Scalars['Int']['output']>;
  priceCuts?: Maybe<Array<PriceCut>>;
  priceHistory?: Maybe<Array<PriceUpdate>>;
  publicationDate?: Maybe<Scalars['Date']['output']>;
  seller: Customer;
  sellerId: Scalars['String']['output'];
  sellingDate?: Maybe<Scalars['Date']['output']>;
  test?: Maybe<Test>;
  thumb?: Maybe<Scalars['String']['output']>;
  undercarriages?: Maybe<Array<Undercarriage>>;
  videoUrl?: Maybe<Scalars['String']['output']>;
  warranties?: Maybe<Array<WarrantyForProduct>>;
  websiteStatus?: Maybe<WebsiteStatus>;
  wheels?: Maybe<Array<Wheel>>;
};


export type ProductPapersArgs = {
  kinds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ProductHit = {
  __typename: 'ProductHit';
  customerId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  productId: Scalars['String']['output'];
  utmCampaign?: Maybe<Scalars['String']['output']>;
  utmSource?: Maybe<Scalars['String']['output']>;
  utmTerm?: Maybe<Scalars['String']['output']>;
};

export type ProductHitInput = {
  productId: Scalars['String']['input'];
  utmCampaign?: InputMaybe<Scalars['String']['input']>;
  utmSource?: InputMaybe<Scalars['String']['input']>;
  utmTerm?: InputMaybe<Scalars['String']['input']>;
};

export type ProductMessageInput = {
  email: Scalars['Email']['input'];
  message: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

export type ProductNegotiationInput = {
  email: Scalars['Email']['input'];
  message: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  price: Scalars['Int']['input'];
};

export type Query = {
  __typename: 'Query';
  agent?: Maybe<Agent>;
  alerts: Array<Alert>;
  bargainingByOfferId?: Maybe<Bargaining>;
  bookingInfos?: Maybe<BookingInfos>;
  brands: Array<Brand>;
  breaking?: Maybe<Breaking>;
  cart?: Maybe<Cart>;
  checkAgentApplicantSubscriptionToken?: Maybe<AgentApplicant>;
  confirmation?: Maybe<Confirmation>;
  confirmations: Array<Confirmation>;
  customerReviewInfo?: Maybe<CustomerReview>;
  departmentsList: Array<Department>;
  emailExists?: Maybe<Scalars['Boolean']['output']>;
  favoriteProducts: Array<FavoriteProductView>;
  myGrayCard?: Maybe<GrayCard>;
  myWarranty?: Maybe<Warranty>;
  nextWorkingDays?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  numberOfInterestedBuyers?: Maybe<Scalars['Int']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  product?: Maybe<Product>;
  productsLikeThis?: Maybe<Array<Maybe<Product>>>;
  profile?: Maybe<Customer>;
  quotation?: Maybe<Quotation>;
  searchModels: SearchCarsResult;
  selectBreaking?: Maybe<BreakingPayment>;
  upcomingInformationMeetings: Array<InformationMeeting>;
  userBySlug?: Maybe<User>;
  validateToken?: Maybe<Scalars['Boolean']['output']>;
  versionsFor: Array<Version>;
};


export type QueryAgentArgs = {
  id: Scalars['Int']['input'];
};


export type QueryAlertsArgs = {
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['Email']['input'];
};


export type QueryBargainingByOfferIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryBookingInfosArgs = {
  productId: Scalars['String']['input'];
};


export type QueryBreakingArgs = {
  id: Scalars['Int']['input'];
};


export type QueryCartArgs = {
  productId: Scalars['String']['input'];
};


export type QueryCheckAgentApplicantSubscriptionTokenArgs = {
  leadPhases?: InputMaybe<Array<AgentApplicantLeadPhase>>;
  token: Scalars['String']['input'];
};


export type QueryConfirmationArgs = {
  productId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<ConfirmationType>;
};


export type QueryCustomerReviewInfoArgs = {
  productId: Scalars['String']['input'];
};


export type QueryEmailExistsArgs = {
  email: Scalars['Email']['input'];
};


export type QueryFavoriteProductsArgs = {
  favoriteListId: Scalars['UUID']['input'];
};


export type QueryMyGrayCardArgs = {
  productId: Scalars['String']['input'];
};


export type QueryMyWarrantyArgs = {
  productId: Scalars['String']['input'];
};


export type QueryNextWorkingDaysArgs = {
  dayCount: Scalars['Int']['input'];
};


export type QueryNumberOfInterestedBuyersArgs = {
  quotationId: Scalars['ID']['input'];
};


export type QueryProductArgs = {
  id: Scalars['String']['input'];
};


export type QueryProductsLikeThisArgs = {
  id: Scalars['String']['input'];
  number?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryQuotationArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySearchModelsArgs = {
  filters: SearchCarsFilters;
};


export type QuerySelectBreakingArgs = {
  breakingId: Scalars['Int']['input'];
  token: Scalars['String']['input'];
};


export type QueryUserBySlugArgs = {
  slug: Scalars['String']['input'];
};


export type QueryValidateTokenArgs = {
  customerId?: InputMaybe<Scalars['Int']['input']>;
  token: Scalars['String']['input'];
  transactionId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryVersionsForArgs = {
  brandId: Scalars['Int']['input'];
};

export type Quotation = {
  __typename: 'Quotation';
  advisorId?: Maybe<Scalars['Int']['output']>;
  agent?: Maybe<Agent>;
  appointmentAvailabilities?: Maybe<AppointmentAvailabilities>;
  automaticCaptureUnsuccessful: Scalars['Boolean']['output'];
  automaticPricing?: Maybe<AutomaticPricing>;
  brand?: Maybe<Scalars['String']['output']>;
  canUpdate: Scalars['Boolean']['output'];
  capture?: Maybe<QuotationCapture>;
  carId?: Maybe<Scalars['Int']['output']>;
  /**
   * Wich button did the customer clicked to book a meeting,
   * equals to DROP if clicked on the old generic button
   */
  clickedBookingCtaType?: Maybe<PriceEstimationEvaluation>;
  condition?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['Date']['output'];
  customer?: Maybe<Customer>;
  customerId?: Maybe<Scalars['Int']['output']>;
  /** The customer's price estimatimation of the car */
  customerPriceEstimation?: Maybe<Scalars['Int']['output']>;
  department?: Maybe<Scalars['String']['output']>;
  desiredSellingDate?: Maybe<Scalars['String']['output']>;
  door?: Maybe<Scalars['Int']['output']>;
  energy?: Maybe<Scalars['String']['output']>;
  engine?: Maybe<Scalars['String']['output']>;
  entryIntoService?: Maybe<Scalars['Date']['output']>;
  forwarding?: Maybe<Scalars['String']['output']>;
  forwardingReason: Array<Maybe<QuotationForwardingReason>>;
  gearTechnology?: Maybe<Scalars['String']['output']>;
  gearType?: Maybe<Scalars['String']['output']>;
  generation?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  licensePlate?: Maybe<Scalars['String']['output']>;
  mileage?: Maybe<Scalars['Int']['output']>;
  motorization?: Maybe<Scalars['String']['output']>;
  noMatchInReferential: Scalars['Boolean']['output'];
  owner?: Maybe<Scalars['String']['output']>;
  packageName?: Maybe<Scalars['String']['output']>;
  partnerSuggestion?: Maybe<PartnerSuggestion>;
  phoneCallRequired?: Maybe<Scalars['Boolean']['output']>;
  price?: Maybe<Scalars['Int']['output']>;
  /** The customer's evaluation of the price estimation given by Capcar */
  priceEstimationEvaluation?: Maybe<PriceEstimationEvaluation>;
  pricingId?: Maybe<Scalars['Int']['output']>;
  product?: Maybe<Product>;
  purpose?: Maybe<Scalars['String']['output']>;
  status: QuotationStatus;
  suggestions: QuotationSuggestions;
  traction?: Maybe<Scalars['String']['output']>;
  unknownBrand: Scalars['Boolean']['output'];
  unknownMotorization: Scalars['Boolean']['output'];
  unknownPackageName: Scalars['Boolean']['output'];
  unknownVersion: Scalars['Boolean']['output'];
  unknownYear: Scalars['Boolean']['output'];
  updatedAt?: Maybe<Scalars['Date']['output']>;
  utmCampaign?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export enum QuotationCapture {
  AUTOMATIC = 'AUTOMATIC',
  MANUAL = 'MANUAL'
}

export enum QuotationField {
  door = 'door',
  energy = 'energy',
  engine = 'engine',
  gearType = 'gearType',
  generation = 'generation',
  mileage = 'mileage',
  packageName = 'packageName',
  traction = 'traction'
}

export enum QuotationForwardingReason {
  NOT_COVERED = 'NOT_COVERED',
  NOT_ELIGIBLE = 'NOT_ELIGIBLE'
}

export type QuotationInput = {
  brand?: InputMaybe<Scalars['String']['input']>;
  capture?: InputMaybe<QuotationCapture>;
  carId?: InputMaybe<Scalars['Int']['input']>;
  condition?: InputMaybe<Scalars['Int']['input']>;
  department?: InputMaybe<Scalars['String']['input']>;
  desiredSellingDate?: InputMaybe<Scalars['String']['input']>;
  door?: InputMaybe<Scalars['Int']['input']>;
  energy?: InputMaybe<Scalars['String']['input']>;
  engine?: InputMaybe<Scalars['String']['input']>;
  entryIntoService?: InputMaybe<Scalars['Date']['input']>;
  forwarding?: InputMaybe<Scalars['String']['input']>;
  gearTechnology?: InputMaybe<Scalars['String']['input']>;
  gearType?: InputMaybe<Scalars['String']['input']>;
  generation?: InputMaybe<Scalars['String']['input']>;
  licensePlate?: InputMaybe<Scalars['String']['input']>;
  mileage?: InputMaybe<Scalars['Int']['input']>;
  motorization?: InputMaybe<Scalars['String']['input']>;
  owner?: InputMaybe<Scalars['String']['input']>;
  packageName?: InputMaybe<Scalars['String']['input']>;
  phoneCallRequired?: InputMaybe<Scalars['Boolean']['input']>;
  pricingId?: InputMaybe<Scalars['Int']['input']>;
  purpose?: InputMaybe<Scalars['String']['input']>;
  subversion?: InputMaybe<Scalars['String']['input']>;
  traction?: InputMaybe<Scalars['String']['input']>;
  unknownBrand?: InputMaybe<Scalars['Boolean']['input']>;
  unknownMotorization?: InputMaybe<Scalars['Boolean']['input']>;
  unknownPackageName?: InputMaybe<Scalars['Boolean']['input']>;
  unknownVersion?: InputMaybe<Scalars['Boolean']['input']>;
  unknownYear?: InputMaybe<Scalars['Boolean']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
  wrongIdentifiedVehicule?: InputMaybe<Scalars['Boolean']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type QuotationRefreshInput = {
  condition: Scalars['Int']['input'];
  desiredSellingDate: Scalars['String']['input'];
  mileage: Scalars['Int']['input'];
  owner: Scalars['String']['input'];
  phoneCallRequired: Scalars['Boolean']['input'];
};

export enum QuotationStatus {
  BLACKLISTED = 'BLACKLISTED',
  FORWARDED = 'FORWARDED',
  PENDING = 'PENDING',
  VALIDATED = 'VALIDATED'
}

export type QuotationSuggestions = {
  __typename: 'QuotationSuggestions';
  brand: Array<Scalars['String']['output']>;
  door: Array<Scalars['Int']['output']>;
  energy: Array<Scalars['String']['output']>;
  gearType: Array<Scalars['String']['output']>;
  generation: Array<Scalars['String']['output']>;
  motorization: Array<Scalars['String']['output']>;
  packageName: Array<Scalars['String']['output']>;
  traction: Array<Scalars['String']['output']>;
  version: Array<Scalars['String']['output']>;
  year: Array<Scalars['Int']['output']>;
};

export enum Rating {
  ACCEPTABLE = 'ACCEPTABLE',
  BAD = 'BAD',
  EXCELLENT = 'EXCELLENT',
  FAIR = 'FAIR',
  GOOD = 'GOOD',
  NONE = 'NONE',
  UNTESTED = 'UNTESTED'
}

export type SearchCarsFilters = {
  brands?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  doors?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  energies?: InputMaybe<Array<InputMaybe<Energy>>>;
  engines?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  gearTechnologies?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  gearTypes?: InputMaybe<Array<InputMaybe<Gearbox>>>;
  generations?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  packageNames?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  phases?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  seats?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  subversions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tractions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  versions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  years?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type SearchCarsFiltersV2 = {
  brand?: InputMaybe<Scalars['String']['input']>;
  door?: InputMaybe<Scalars['Int']['input']>;
  energy?: InputMaybe<Energy>;
  gearType?: InputMaybe<Gearbox>;
  generation?: InputMaybe<Scalars['String']['input']>;
  motorization?: InputMaybe<Scalars['String']['input']>;
  packageName?: InputMaybe<Scalars['String']['input']>;
  traction?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

export type SearchCarsResult = {
  __typename: 'SearchCarsResult';
  brands: Array<Maybe<Scalars['String']['output']>>;
  categories: Array<Maybe<Scalars['String']['output']>>;
  doors: Array<Maybe<Scalars['Int']['output']>>;
  energies: Array<Maybe<Energy>>;
  engines: Array<Maybe<Scalars['String']['output']>>;
  gearTechnologies: Array<Maybe<Scalars['String']['output']>>;
  gearTypes: Array<Maybe<Gearbox>>;
  generations: Array<Maybe<Scalars['String']['output']>>;
  packageNames: Array<Maybe<Scalars['String']['output']>>;
  phases: Array<Maybe<Scalars['String']['output']>>;
  seats: Array<Maybe<Scalars['Int']['output']>>;
  subversions: Array<Maybe<Scalars['String']['output']>>;
  tractions: Array<Maybe<Scalars['String']['output']>>;
  versions: Array<Maybe<Scalars['String']['output']>>;
  years: Array<Maybe<Scalars['Int']['output']>>;
};

export type SearchPaperInput = {
  kinds?: InputMaybe<Array<InputMaybe<PaperKind>>>;
  productId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type SetCustomerPriceEstimationResult = {
  __typename: 'SetCustomerPriceEstimationResult';
  customerPriceEstimation: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
};

export type Share = {
  __typename: 'Share';
  customerId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  isMobile: Scalars['Boolean']['output'];
  productId: Scalars['String']['output'];
  useNatif: Scalars['Boolean']['output'];
  userAgent: Scalars['String']['output'];
};

export type ShareInput = {
  customerId?: InputMaybe<Scalars['Int']['input']>;
  isMobile: Scalars['Boolean']['input'];
  productId: Scalars['String']['input'];
  useNatif: Scalars['Boolean']['input'];
  userAgent: Scalars['String']['input'];
};

export type Sort = {
  direction?: InputMaybe<SortDirection>;
  field: Scalars['String']['input'];
};

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum Status {
  CANCELLED = 'CANCELLED',
  CREDIT = 'CREDIT',
  EXPIRED = 'EXPIRED',
  PAID = 'PAID',
  PENDING = 'PENDING',
  REFUNDED = 'REFUNDED'
}

export type Test = {
  __typename: 'Test';
  comment?: Maybe<Scalars['String']['output']>;
  hasGoodGripOfTheClutch: Scalars['Boolean']['output'];
  hasNoVibrationInSteeringColumnAndSuspension: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isDrivingKeepingInLineAndOnlineBraking: Scalars['Boolean']['output'];
  maximumSpeedReachedDuringTest: Scalars['Int']['output'];
  motorHasNoAbnormalsVibrations: Scalars['Boolean']['output'];
  normalBehaviourOfDirection: Scalars['Boolean']['output'];
  productId: Scalars['String']['output'];
  riseHotWithLinearAcceleration?: Maybe<Scalars['Boolean']['output']>;
  temperatureIncreaseNormally?: Maybe<Scalars['Boolean']['output']>;
};

export enum TireType {
  ALL_SEASON = 'ALL_SEASON',
  SUMMER = 'SUMMER',
  WINTER = 'WINTER'
}

export type Token = {
  __typename: 'Token';
  customerId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  transactionId?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type Tracker = {
  campaign?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  medium?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  term?: InputMaybe<Scalars['String']['input']>;
};

export type Transaction = {
  amount: Scalars['Int']['output'];
  createdAt: Scalars['Date']['output'];
  error?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  source?: Maybe<TransactionSource>;
  status: Status;
  updatedAt: Scalars['Date']['output'];
};

export type TransactionInfo = {
  __typename: 'TransactionInfo';
  customerEmail?: Maybe<Scalars['String']['output']>;
  customerId?: Maybe<Scalars['Int']['output']>;
  grayCardId?: Maybe<Scalars['Int']['output']>;
  orderId?: Maybe<Scalars['Int']['output']>;
  productId?: Maybe<Scalars['String']['output']>;
  transactionId?: Maybe<Scalars['String']['output']>;
  warrantyId?: Maybe<Scalars['Int']['output']>;
};

export enum TransactionSource {
  CASH = 'CASH',
  CHECK = 'CHECK',
  MANGOPAY = 'MANGOPAY',
  PAYZEN = 'PAYZEN',
  UNKNOWN = 'UNKNOWN',
  WIRE_TRANSFER = 'WIRE_TRANSFER'
}

export type TransactionWithType = {
  __typename: 'TransactionWithType';
  amount?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<Status>;
  transactionType?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type Undercarriage = {
  __typename: 'Undercarriage';
  cardansInGoodConditions: CardanInGoodCondition;
  comment?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isDamperInGoodConditions: Scalars['Boolean']['output'];
  isSteeringBallJointInGoodConditions: Scalars['Boolean']['output'];
  position?: Maybe<UndercarriagePosition>;
  productId: Scalars['String']['output'];
};

export enum UndercarriagePosition {
  FRONT = 'FRONT',
  REAR = 'REAR'
}

export type UpdateAgentApplicantEnrollmentInput = {
  startingDate?: InputMaybe<Scalars['Date']['input']>;
};

export type UpdateConfirmationInput = {
  confirmed?: InputMaybe<Scalars['Boolean']['input']>;
  productId?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<ConfirmationType>;
};

export type UpdatePaperInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['ID']['input'];
  kind?: InputMaybe<PaperKind>;
  pic?: InputMaybe<Scalars['Upload']['input']>;
  productId: Scalars['String']['input'];
  source?: InputMaybe<PaperSource>;
  status: PaperStatus;
};

export type User = {
  __typename: 'User';
  codeAgent?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
};

export enum UserGender {
  MAN = 'MAN',
  WOMAN = 'WOMAN'
}

export type Version = {
  __typename: 'Version';
  id: Scalars['Int']['output'];
  mostSearched: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type Warranty = Transaction & {
  __typename: 'Warranty';
  amount: Scalars['Int']['output'];
  createdAt: Scalars['Date']['output'];
  duration: Scalars['Int']['output'];
  error?: Maybe<Scalars['String']['output']>;
  externalWarrantyId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  paymentFrequency: WarrantyPaymentFrequency;
  provider?: Maybe<WarrantyProvider>;
  source?: Maybe<TransactionSource>;
  status: Status;
  type: Scalars['String']['output'];
  updatedAt: Scalars['Date']['output'];
};

export type WarrantyForProduct = {
  __typename: 'WarrantyForProduct';
  amount?: Maybe<Scalars['Int']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<WarrantyType>;
};

export enum WarrantyPaymentFrequency {
  MONTHLY = 'MONTHLY',
  UPFRONT = 'UPFRONT'
}

export enum WarrantyProvider {
  AFFINICAR = 'AFFINICAR',
  CAPCAR = 'CAPCAR',
  CONSTRUCTOR = 'CONSTRUCTOR',
  LABEL_GARANTIE = 'LABEL_GARANTIE'
}

export enum WarrantyType {
  BASIC_MONTHLY = 'BASIC_MONTHLY',
  BASIC_ONE_OFF = 'BASIC_ONE_OFF',
  BASIC_SPECIAL_MONTHLY = 'BASIC_SPECIAL_MONTHLY',
  BASIC_SPECIAL_ONE_OFF = 'BASIC_SPECIAL_ONE_OFF',
  COMFORT = 'COMFORT',
  CONSTRUCTOR = 'CONSTRUCTOR',
  ESSENTIAL_MONTHLY = 'ESSENTIAL_MONTHLY',
  ESSENTIAL_ONE_OFF = 'ESSENTIAL_ONE_OFF',
  ESSENTIAL_SPECIAL_MONTHLY = 'ESSENTIAL_SPECIAL_MONTHLY',
  ESSENTIAL_SPECIAL_ONE_OFF = 'ESSENTIAL_SPECIAL_ONE_OFF',
  EXCELLENCE_MONTHLY = 'EXCELLENCE_MONTHLY',
  EXCELLENCE_ONE_OFF = 'EXCELLENCE_ONE_OFF',
  EXCELLENCE_SPECIAL_MONTHLY = 'EXCELLENCE_SPECIAL_MONTHLY',
  EXCELLENCE_SPECIAL_ONE_OFF = 'EXCELLENCE_SPECIAL_ONE_OFF',
  MEDIUM_MONTHLY = 'MEDIUM_MONTHLY',
  MEDIUM_ONE_OFF = 'MEDIUM_ONE_OFF',
  MEDIUM_SPECIAL_MONTHLY = 'MEDIUM_SPECIAL_MONTHLY',
  MEDIUM_SPECIAL_ONE_OFF = 'MEDIUM_SPECIAL_ONE_OFF',
  MONTHLY = 'MONTHLY',
  NOT_NEEDED = 'NOT_NEEDED',
  OPTIMAL_MONTHLY = 'OPTIMAL_MONTHLY',
  OPTIMAL_ONE_OFF = 'OPTIMAL_ONE_OFF',
  OPTIMAL_SPECIAL_MONTHLY = 'OPTIMAL_SPECIAL_MONTHLY',
  OPTIMAL_SPECIAL_ONE_OFF = 'OPTIMAL_SPECIAL_ONE_OFF',
  OPTIMUM = 'OPTIMUM',
  PREMIUM = 'PREMIUM',
  SECURITY = 'SECURITY',
  SERENITY = 'SERENITY',
  SERENITY_2 = 'SERENITY_2',
  SERENITY_3 = 'SERENITY_3'
}

export enum WebsiteStatus {
  FOR_SALE = 'FOR_SALE',
  RETIRED = 'RETIRED'
}

export type Wheel = {
  __typename: 'Wheel';
  brand: Scalars['String']['output'];
  comment?: Maybe<Scalars['String']['output']>;
  depth: Scalars['Float']['output'];
  diameter: Scalars['Int']['output'];
  height: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  position: Position;
  productId: Scalars['String']['output'];
  rimCondition?: Maybe<Scalars['Float']['output']>;
  tireType?: Maybe<TireType>;
  width: Scalars['Int']['output'];
};

export enum YearsAs {
  FOUR_OR_MORE = 'FOUR_OR_MORE',
  ONE = 'ONE',
  THREE = 'THREE',
  THREE_OR_MORE = 'THREE_OR_MORE',
  TWO = 'TWO',
  ZERO = 'ZERO'
}
